import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import SEO from "../components/SEO"
import { FaShareSquare, FaGithubSquare } from "react-icons/fa"

const ComponentName = ({ data }) => {
  const { content, title, image, description, url, github } = data.blog

  return (
    <Layout>
      <SEO title={title} description={description} />
      <section className="blog-template">
        <div className="section-center">
          <article className="blog-content">
            <h1>{title}</h1>
            
            <Image fluid={image.childImageSharp.fluid} className="project-img-hero" />
            {
              url && (
                <><a href={url} className="project-url-link"><p className="project-url-text"><span>View live website</span>
                <FaShareSquare className="project-icon" />
              </p></a></>
              )
            }
            {
              github && (
                <><a href={github} className="project-url-link"><p className="project-url-text"><span>View GitHub repository</span>
                <FaGithubSquare className="project-icon" />
              </p></a></>
              )
            }
            <ReactMarkdown source={content} />
          </article>
          <Link to="/projects" className="btn center-btn">
            projects
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleProject($slug: String) {
    blog: strapiProjects(slug: { eq: $slug }) {
      content
      title
      description
      url
      github
      image {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default ComponentName